<template>
    <div v-if="data_loading" class="loader-wrapper">
        <BaseLoader></BaseLoader>
    </div>
    <div v-else>
        <div>
            <h2 class="font-2-basic header-title">Create scenario</h2>
        </div>
        <CreateSimulation @close-dialog="$emit('close-dialog')" />
    </div>
</template>

<script>
    import BaseLoader from '@/components/ui_items/BaseLoader.vue';
    import CreateSimulation from '@/components/functions/CreateSimulation.vue'

    export default {
        components: {
            BaseLoader,
            CreateSimulation
        },
        data() {
            return {
                data_loading: false
            }
        }
        
    }
</script>

<style scoped>
.nav-buttons {
    width: 100%;
}
.header-title {
    font-size: 1.2rem;
    color: var(--font1);
}
.loader-wrapper {
    display: flex;
    width: 100%;
    height: 20rem;
    justify-content: center;
    align-items: center;
}
</style>