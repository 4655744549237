<template>
    <div>
        <Card class="connect-card base-card font-sans disabled">
            <template #title>
                Connect to simulation
            </template>
            <template #content>
                <div class="card-content" v-if="$store.getters.vmStatus === 'running'">
                    <img class="connect-img" src="@/assets/connect_img.svg">
                    <div class="input-group p-input-filled wwd-column">
                        <label class="label-font" for="username1">IP Adress</label>
                        <it-input disabled v-model="$store.state.simu_connect_ip" class="connect-input p-inputtext-sm" type="text" />
                        <label class="label-font" for="username1">Port</label>
                        <it-input disabled v-model="$store.state.simu_connect_port" class="connect-input p-inputtext-sm" type="text" />
                        <label class="label-font" for="username1">Refresh ratio [ms]</label>
                        <it-input :disabled="!canConnectToSimmulation" v-model="$store.state.refresh_ratio" class="connect-input p-inputtext-sm" type="number" :min="250" />
                        <div class="info-label"> 
                            <i class="pi pi-info-circle" style="color: var(--woodward_red);"></i>
                             <small>
                                Simulation must be running and all devices have to be in the active state to connect.
                             </small>
                         </div>
                        </div>
                        <div class="card-footer" v-if="$store.getters.vmStatus === 'running'">
                            <div class="buttons-wrapper" v-if="connectionStatus !== 2">
                                <Button :disabled="!canConnectToSimmulation" :loading="buttonSpinning" label="Connect" @click="connectToSimulation()" class="p-button-success p-button-raised localhost-button" />
                            </div>
                            <div class="buttons-wrapper" v-else>
                                <Button label="Disconnect" @click="disconnectFromSimulation()" class="p-button-danger p-button-raised disconnect-button" />
                            </div>
                        </div>
                </div>
                <div class="loading-text" v-else>
                    Turn on your simubox and run the model to connect
                </div>
            </template>
            <template #footer>
            </template>
        </Card>    
            </div>
</template>

<script>
    import Card from 'primevue/card';
    import Button from 'primevue/button';
    
    export default {
        components: {
            Card,
            Button,
        },
        data() {
            return {
                getting: null,
                show_speedial: false,
                vm_list: [],
                selected_machine: null,
            }
        },
        methods: {
            async connectToSimulation() {
                this.$store.state.localhost_connection = true
                this.$store.dispatch('connectToSimulation', {ip: this.$store.state.simu_connect_ip, port: this.$store.state.simu_connect_port})
                this.$store.commit('setConnectFlag', false)

            },
            disconnectFromSimulation() {
                this.getting = false
                clearInterval(this.$store.state.connection_check_interval)
                this.$store.commit('setIpAdresses', {ip: '', port: ''})
                this.$store.dispatch('disconnectFromVm')
                this.$store.state.localhost_connection = false
            },
        },
        computed: {
            connectActive() {
               return this.$store.getters.vmState === 'running' ? false : true
            },
            connectionStatus() {
                return this.$store.getters.connectionStatus
            },
            buttonSpinning() {
                return this.$store.getters.connectionStatus === 1 ? true : false
            },
            vmIp() {
                return this.$store.getters.vmIp
            },
            canConnectToSimmulation() {
                return !['no data', 'none'].includes(this.$store.getters.currentModel.toLowerCase()) && this.$store.getters.deviceList.every(device => device.active === true )
            }
        },
        watch: {
            vmIp(newIp, oldIp) {
                if (newIp !== null && newIp !== oldIp ) {
                    this.$store.state.simu_connect_ip = newIp
                } 
            }
        },
        mounted() {
            if (this.vmIp) {
                this.$store.state.simu_connect_ip = this.vmIp
            }
        }
    }
</script>

<style scoped>
.connect-card {
    height: 34rem;
}
.connect-card:deep(.p-card-title) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin-bottom: 1.5rem;
}
.card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 21rem;
    font-size: 16px; 
}
.connect-img {
    width: 400px;
    height: 400px;
    
}
.input-group {
    width: 20rem;
}

.input-group > .connect-input {
    margin: 0.5rem;
}
.buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.localhost-button {
    min-width: 12rem;
    min-width: 10rem;
    border: 1px var(--color7) solid !important;
}
.disconnect-button {
    width: 12rem;
    background-color: var(--red2) !important;
    border: 1px var(--color7) solid !important;
}
::v-deep(.it-input-wrapper--disabled) {
    border-color: var(--color10) !important;
}
.info-label {
    line-height: 20px;
    margin-top: 0.2rem;
}
.loading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    font-size: 0.85rem;
    color: var(--color7);
    text-align: center;
}
.card-footer {
    margin-top: 1.5rem;
}

</style>