<template>
    <Card class="simubox-card base-card">
        <template #title>
            <span> Model control </span>
            <div class="model-control-wrapper wwd-row" v-if="$store.getters.vmStatus == 'running'">
                <span class="p-input-icon-left filter-input">
                    <i class="pi pi-search" />
                    <InputText type="text" v-model="filter_input" placeholder="Search" />
                </span>
                <div class="model-status">
                    <span class="load-info-label font-sans"> Running model </span>
                    <div class="load-info-button">
                        <Tag v-if="['no data', 'none'].includes(this.$store.getters.currentModel.toLowerCase())" class="no-model-tag" severity="secondary" value="No model running"></Tag>
                        <Tag v-else-if="!$store.getters.deviceList.every(device => device.active === true )" class="starting-tag" severity="secondary" value="Model starting..."></Tag>
                        <Tag v-else class="running-tag" :value="$store.getters.currentModel"></Tag>
                    </div>
                </div>
            </div>
        </template>
        <template #content>
            <ScrollPanel class="table">
                <div class="loading-placeholder" v-if="$store.getters.vmStatus !== 'running' || ($store.getters.vmStatus === 'running' && !rdpReady )">
                    <!-- <ProgressSpinner /> -->
                    <div>
                        Turn on your Simubox to see available models.
                    </div>
                </div>
                <div class="loading-placeholder" v-else-if="$store.getters.modelList.length === null">
                    <!-- <ProgressSpinner /> -->
                    <div>
                        After Simubox starts up, <br> it takes a few minutes to load the models...
                    </div>
                </div>
                <div class="loading-placeholder" v-else-if="$store.getters.modelList.length === 0">
                    <!-- <ProgressSpinner /> -->
                    <div>
                        No models found in simubox
                    </div>
                </div>
                <DataTable class="table" :value="dataFiltered" v-else>
                    <Column field="name" header="Model name"></Column>
                    <Column field="actions" header="Action">
                        <template #body="{data}">
                            <span class="mr-5"> 
                                <Button v-if="data.name == $store.getters.currentModel" :disabled="model_starting" :loading="model_starting" label="Restart model" icon="pi pi-refresh" class="action-button p-button-warning p-button-raised p-button-sm" @click="startModel(data.name)" />
                                <Button v-else :disabled="model_starting" label="Start model" icon="pi pi-play" class="action-button p-button-success p-button-raised p-button-sm" @click="startModel(data.name)" />
                            </span>
                        </template>
                    </Column>
                    <Column field="Docs" header="Docs">
                        <template #body={data}>
                            <span class="mr-5"> 
                                <Button :loading="data.name === loadingDoc" label="Documentation" icon="pi pi-file-pdf" class="action-button p-button p-button-secondary p-button-text p-button-sm" @click="downloadPdfFile(data.name)" />
                            </span>
                        </template>
                    </Column>
                </DataTable>
            </ScrollPanel>
        </template>
        <template #empty>
            <div> No models found </div>
        </template>      
        {{ matchingScenario }} 
    </Card>
 
</template>

<script>
    import Card from 'primevue/card';
    import Button from 'primevue/button'
    import Tag from 'primevue/tag';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';
    import ScrollPanel from 'primevue/scrollpanel';
    // import ProgressSpinner from 'primevue/progressspinner';
    export default {
        components: {
            Card,
            Button,
            Tag,
            DataTable,
            Column,
            InputText,
            ScrollPanel
            // ProgressSpinner
        },
        data() {
            return {
                model_starting: false,
                filter_input: null,
                loadingDoc: null
            }
        },
        methods: {
            async startModel(model) {
                this.model_starting = true

                let defaultScenario = this.scenariosStructured.filter(scenario => scenario.name.split('_').at(-1) === model.split('_').at(-1))


                if (defaultScenario.length) {

                    // special cases
                    if (model.includes('EG3500P2_4_LS612P1_2')) {
                        const special_scenarios = defaultScenario.filter(scenario => scenario.name.includes('x'))

                        if (special_scenarios) {
                            defaultScenario = special_scenarios
                        }
                    }

                    this.changeScenario(defaultScenario[0].name)
                    this.$toast.add({severity:'info', summary: 'Scenario changed', detail:`The default scenario for this model has been selected.`, life: 5000});
                } else {
                    this.$toast.add({severity:'warn', summary: 'No default scenario', detail:`No default scenario found to fit this model, set the scenario manually.`, life: 5000});
                    this.changeScenario(null)
                }

                setTimeout(() => {
                    this.model_starting = false
                }, 12000);
                this.$store.dispatch('clearSequence')
                await this.$store.dispatch('startModel', model)
            },
            async changeScenario(scenario) {
                this.$store.commit('setUrl', {
                    chosed_simulation : scenario
                })
                this.$store.dispatch('firebase')
            },
            async downloadPdfFile(name) {
                this.loadingDoc = name
                await this.$store.dispatch('downloadPdfFile', this.downloadName(name))
                this.loadingDoc = null
            },
            downloadName(name) {
                return name.split('.')[0].slice(0, -2) + '.pdf'
            }
        },
        computed: {
            dataFiltered() {
                return this.filter_input ? this.$store.getters.modelList.filter(element => element.name.toLowerCase().includes(this.filter_input.toLowerCase())) : this.$store.getters.modelList
            },
            rdpReady() {
                return !['Simubox unreachable', 'Simubox not ready'].includes(this.$store.getters.vmRdp)
            },
            matchingScenario() {
                return this.scenarios_formated.filter(scenario => scenario.scenario_tags.split(',').some(tag => this.$store.getters.currentModel.includes(tag)))
            },
            scenariosList() {
                return this.$store.getters.listOfSimulations
            },
            scenariosStructured() {
                return Object.keys(this.scenariosList).map(key => ({
                    name: key,
                    ...this.scenariosList[key]
                }))
            }
        },
    }
</script>

<style scoped>
.simubox-card {
    height: 31rem;
}
.simubox-card:deep(.p-card-title) {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}

.load-info-label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 400 !important;
}
.load-info-label {
    margin-top: 0.3rem;
    margin-right: 0.4rem;
    font-size: 1.1rem;
}
.running-tag {
    background-color: var(--color4) !important;
    font-weight: 600;
    border-color: var(--color8);
}
.starting-tag {
    background-color: var(--orange) !important;
    font-weight: 600;
    border-color: var(--color8);
}
.no-model-tag {
    background-color: var(--color3) !important;
    font-weight: 600;
    border-color: var(--color8);
}
.table {
    height: 25rem !important;
}
.vm-notrunning-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
}
.filter-input {
    margin-right: 0.5rem;
}
.model-control-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.model-status {
    display: flex;
    flex-direction: row;
}
.loading-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.85rem;
    color: var(--color7);
    text-align: center;
}
@media (max-width: 700px) {
    .model-control-wrapper {
        display: flex;
        flex-direction: column;
    }
}
</style>