<template>
    <Card class="simubox-card base-card">
        <template #title>
            <span> Devices preview </span>
        </template>
        <template #content>
            <ScrollPanel  class="table">
                <div v-if="['No data', 'None'].includes($store.getters.currentModel) || this.$store.getters.vmStatus !== 'running'" class="vm-notrunning-placeholder">
                    To see simulated devices, the model must be running.
                </div>
                <DataTable v-else class="table" :value="$store.getters.deviceList" responsiveLayout="scroll">
                    <Column header="Device">
                        <template #body="{data}">
                            <img class="device-img" :src="deviceImage(data.type)" alt="Device image">
                        </template>
                    </Column>
                    <Column field="type" header="Type"></Column>
                    <Column field="id" header="ID"></Column>
                    <Column field="ip_address" header="IP Adresses">
                        <template #body="{data}">
                            <div class="wwd-column">
                                <span v-for="ip in data.ip_address" :key="ip"> {{ ip }} </span>
                            </div>
                        </template>
                    </Column>
                    <Column field="active" header="Status">
                        <template #body="{data}">
                                <Tag :severity="data.active ? 'success' : 'danger'" :value="data.active ? 'Active' : 'Starting...'"></Tag>
                        </template>
                    </Column>
                </DataTable>
            </ScrollPanel>
        </template>        
    </Card>
</template>

<script>
    import Card from 'primevue/card';
    import Tag from 'primevue/tag';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import ScrollPanel from 'primevue/scrollpanel';
    export default {
        components: {
            Card,
            Tag,
            DataTable,
            Column,
            ScrollPanel
        },
        methods: {
            deviceImage(device) {
                if (device.includes('EG')) {
                    return require('@/assets/easyi.png')
                }
                else if (device.includes('LS6')) {
                    return require('@/assets/ls6.png')
                }
                else if (device.includes('GC')) {
                    return require('@/assets/gc.png')
                }
                else if (device.includes('EI')) {
                    return require('@/assets/easyi.png')
                } else {
                    return require('@/assets/segment.png')
                }
            }
        }
    }
</script>

<style scoped>
.simubox-card {
    height: 31rem;
}
.simubox-card:deep(.p-card-title) {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}

.load-info-label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 400 !important;
}
.load-info-label {
    margin-top: 0.3rem;
    margin-right: 0.4rem;
    font-size: 1.1rem;
}
.table {
    height: 25rem !important;
}
.device-img {
    width: 5rem;
}
.vm-notrunning-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.85rem;
    color: var(--color7);
    text-align: center;
}
 
</style>